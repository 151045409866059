:root {
    --main-bg-color: #1e1a20;
    --highlight-color: #89bef5;
    --text-color: #ffeded;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Cabin', sans-serif;

}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

html {
    background: var(--main-bg-color);
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    background: var(--main-bg-color);
    color: var(--text-color);
}

h1 {
    font-size: 8vmin;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
}

h2 {
    font-size: 4vmin;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 80vw;
    color: var(--highlight-color);
    margin-bottom: 40px;
}

.sub-title {
    margin-left: 25px;
    color: var(--highlight-color);
}

.sub-title-about-me {
    margin-bottom: 20px;
}

h3 {
    font-size: 2vmin;
    font-weight: bold;
    margin-bottom: 0;
}

ul, ol {
    margin-left: 1.1em;
    text-align: left;
    list-style: none;
}

li::before {
    content: "";
    border-color: transparent #89bef5;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;
}

.hi-intro {
    padding-top: 10em;
    padding-left: 4px;
    margin-bottom: 10px;
    color: var(--subtle-text-color);
}


/*********************************************/
/* about section */
.abstract {
    font-size: 2vmin;
    width: 600px;
    max-width: 80vw;
}

.about-container {
    font-size: 2vmin;
    width: 700px;
    max-width: 80vw;
    color: #FFEDED;
    padding: 6px;
}

.abstract-img-and-text {
    display: flex;
    flex-direction: row;
}

.profile-image {
    width: 280px;
    height: 373px;
    padding-right: 20px;
    border-radius: 4px;
}

.profile-text {
    padding: 2px;
    border-radius: 4px;
}
/* about section */
/*********************************************/

/*********************************************/
/* contact form section */

.contact-form-label {
    display: inline-block;
    text-align: left;
}

.contact-form-input {
    display: inline-block;
    text-align: left;
    width: 280px;
    background-color: #ffeded;
    height: 60px;
    font-size: 16px;
    vertical-align: middle;
}

.section.contact {
    align-items: flex-start;
    padding-top: 0vh;
}

/* contact form section */
/*********************************************/

/*********************************************/
/* generic button section */

.generic-button {
    background-color: #1e1a20;
    height: 50px;
    color: #ffeded;
    padding: 15px 32px;
    margin: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ffeded;
    border-radius: 10px;
}

/* generic button section */
/*********************************************/

/*********************************************/
/* glow button button section */

.glow-on-hover {
    width: 220px;
    height: 50px;
    outline: none;
    font-size: 2vmin;
    color: #ffeded;
    background: #1e1a20;
    border: 1px solid #ffeded;
    cursor: pointer;
    z-index: 0;
    border-radius: 10px;
    min-width: 200px;
    margin-top: 1em;
    position: absolute;
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.see-more-button {
    height: 70px;
    width: 240px;
    top: 85vh;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    background-color: #1e1a20;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e1a20;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

/* glow button button section */
/*********************************************/


/*********************************************/
/* projects section */

.project-container {
    margin: 20px auto;
    width: 800px;
    display: grid;
    grid-template-columns: 400px 400px;
    grid-row: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.project-box{
    border-radius:10px;
    color:#fff;
    display:flex;
    flex-wrap: wrap;
    font-size:40px;
    font-family:sans-serif;
    margin-left: 20px;
    position: relative;

}

.project-box:hover{
    cursor: pointer;
}

.project-title{
    margin-left: 4px;
    margin-top: 4px;
    width: 340px;
    padding:4px;
    font-size: 0.8em;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: capitalize;
}

.project{
    margin:4px;
    position: relative;
    width: 85%;
}


.logo{
    margin:6px;
    width: 50px;
    height: 50px;
    display: block;
    margin-left: auto;
}

/* projects section */
/*********************************************/

/*********************************************/
/* experience section */

.experience-container {
    font-size: 1.65vmin;
}

.experience-element {
    border-top: 1px solid #ffeded;
    margin-bottom: 50px;
    padding: 10px;
}

.experience-date {
    color: #89bef5;
}

.company {
    color: #FFEDED;
}

.experience-role {
    font-size: 2vmin;
    margin-bottom: 20px;
}

.section.experience {
    align-items: flex-start;
}

/* experience section */
/*********************************************/


/*********************************************/
/* section section */



.section {
    display: flex;
    position: relative;
    color: #DAC7CE;
    font-size: 7vmin;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 10vh;
    align-items: flex-start;
}

.section.intro {
    align-items: flex-start;
    height: 100vh;
    margin-top: 0vh;
}


.project {
    display: flex;
    position: relative;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

.footer {
    width: 100%;
    align-items: center;
    position: relative;
    color: #1e1a20;
    background-color: #ffeded;
    text-align: center;
    margin-top: 10em;
    padding-top: 20px;
    padding-bottom: 20px;
}

section:nth-child(odd) {
    justify-content: flex-end;
}

/* section section */
/*********************************************/


/*********************************************/
/* socials section */

.copyright {
    margin-top: 15px;
}

.socials-button {
    margin-right: 20px;
    border: none;
}

.socials-button:hover{
    cursor: pointer;
}


input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 46px;
    resize: vertical;
}

input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* socials section */
/*********************************************/


/*********************************************/
/* media query section */



@media screen and (max-width: 700px) {
    .project-container {
        width: 300px;
        grid-template-columns: 300px;
    }
    .project-box, .contact-form-input, .glow-on-hover {
        height: 550px;
        width: 300px;
        font-size: 4vmin;
    }
    .contact-from {
        width: 90%;
    }
    h1, h2, h3, .abstract, .profile-text, .experience-container, .experience-role {
        font-size: 3vmin;
    }
    .abstract-img-and-text {
        flex-direction: column;
    }
    .profile-image {
        padding-right: 0;
        padding-bottom: 20px;
    }
    .see-more-button {
        top: 75vh;
    }
}

@media screen and (max-width: 440px) {
    .project-container {
        width: 280px;
        grid-template-columns: 280px;
    }

    .contact-form-input {
        width: 260px;
    }
}

/* media query section */
/*********************************************/
